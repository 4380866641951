import {Injectable} from '@angular/core';
import {WebserviceService} from './webservice.service';

export interface Trator {
    trator: string;
    potencia: string;
}

export interface Plantadeira {
    plantadeira: string;
    linhas: string;
}

export interface Culturas {
    cultura: string;
    populacao_de: string;
    populacao_ate: string;
}

export interface Fazenda {
    nome: string;
    numero: number;
    inscricao_estadual: string;
    area: number;
    espacamento: number;
    observacoes: string;

    cidade: string;
    complemento: string;
    bairro: string;


    palhada: string;
    solo: string;
    umidade: string;
    adubacao: string;

    culturas: any;
    plantadeiras: any;
    tratores: any;
}
export interface Contato {
    ven_id: number;
    id: number;
    feira: string;
    data: string;
    nome_completo: string;
    fazenda: Fazenda;
    area: string;
    cpf_cnpj: string;
    inscricao_est: string;
    cep: string;
    endereco: string;
    numero: string;
    complemento: string;
    bairro: string;
    estado: string;
    cidade: string;
    telefone: string;
    email: string;
    compra: string;
    demonstracao: string;
    plantadeiras: Plantadeira[];
    tratores: Trator[];
    culturas: Culturas[];
    cultura: string;
    populacaoDe: string;
    populacaoAte: string;
    espacamento: string;
    solo: string;
    umidade: string;
    palhada: string;
    adubacao: string;
    observacoes: string;
    added: boolean;
    imagens: string[];
    pais: string;
}

@Injectable()
export class InteressadoService {

    constructor(
        private ws: WebserviceService
    ) {
    }

    public async cadastrar(contato: Contato) {
        return this.ws.doPost<{ con_id: number }>('clientes', contato);
    }

    public async getContatos() {
        return this.ws.doGet<Contato[]>('contato/');
    }

    public async getContato(id: number) {
        return this.ws.doGet<Contato>(`clientes/${id}`);
    }

    public async getContatoPorTelefone(id: string) {
        //id = id.replace(/\D/g, '');
        return this.ws.doGet<Contato>(`clientes/procurar/${id}`);
    }

    public async delContato(id: number) {
        return this.ws.doDelete(`clientes/${id}`);
    }

    public async getCampos() {
        return this.ws.doGet(`campos-dinamicos`);
    }
}
