import {Injectable} from '@angular/core';
import {CanActivate, CanLoad, Router} from '@angular/router';
import {AuthService} from './auth.service';

@Injectable({
    providedIn: 'root'
})
export class NotAuthService implements CanActivate,CanLoad {
    constructor(private router: Router,private auth: AuthService) {
    }
    async canActivate() {
        // console.log("Chamando essa funcao rs");
        if ((await this.auth.isLogado())) {
            // console.log("Ta logado vai pra home!");
            this.router.navigate(['home']);
            return false;
        }
        return true;
    }

    async  canLoad() {
        return await this.canActivate();
    }
}
