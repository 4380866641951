import {Component, Input} from '@angular/core';
import {ModalController, NavParams} from '@ionic/angular';
import {WebserviceService} from '../../services/webservice.service';

@Component({
    selector: 'app-assinatura-modal',
    templateUrl: './assinatura-modal.page.html',
    styleUrls: ['./assinatura-modal.page.scss'],
})
export class AssinaturaModalPage {
    nome;

    // Variables for referencing the canvas and 2dcanvas context
    canvas;
    ctx;

    // Variables to keep track of the mouse position and left-button status
    mouseX;
    mouseY;
    mouseDown = 0;
    offsetX;
    offsetY;

    // Data passed in by componentProps
    @Input() id: number;
    @Input() name: string;
    @Input() type: string;
    width: any;

    constructor(private navParams: NavParams, private ws: WebserviceService, private modalCtrl: ModalController) {
        this.id = navParams.get('id');
        this.name = navParams.get('name');
        this.type = navParams.get('type') == 'return' ? navParams.get('type') : '';
        this.width = window.innerWidth;
    }

    // Clear the canvas context using the canvas width and height
    clearCanvas() {
        this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);
    }

    handleStart(ev) {
        // @ts-ignore
        this.mouseX = ev.touches[0].pageX - this.offsetX - document.querySelector('.modal-wrapper').offsetLeft;
        // @ts-ignore
        this.mouseY = ev.touches[0].pageY - this.offsetY - document.querySelector('.modal-wrapper').offsetTop;
    }

    handleMove(ev) {
        // @ts-ignore
        const currentX = ev.touches[0].pageX - this.offsetX - document.querySelector('.modal-wrapper').offsetLeft;
        // @ts-ignore
        const currentY = ev.touches[0].pageY - this.offsetY - document.querySelector('.modal-wrapper').offsetTop;

        this.ctx.beginPath();
        this.ctx.lineJoin = 'round';
        this.ctx.moveTo(this.mouseX, this.mouseY);
        this.ctx.lineTo(currentX, currentY);
        this.ctx.closePath();
        this.ctx.strokeStyle = 'black';
        this.ctx.lineWidth = 1;
        this.ctx.stroke();

        this.mouseX = currentX;
        this.mouseY = currentY;
    }

    // Set-up the canvas and add our event handlers after the page has loaded
    ionViewDidEnter() {
        // Get the specific canvas element from the HTML document
        this.canvas = document.getElementById('sketchpad');
        this.offsetX = this.canvas.offsetLeft;
        this.offsetY = this.canvas.offsetTop;

        // If the browser supports the canvas tag, get the 2d drawing context for this canvas
        if ((this.canvas as HTMLCanvasElement).getContext) {
            this.ctx = (this.canvas as HTMLCanvasElement).getContext('2d');
        }
    }

    saveCanvasImage() {
        const dataUrl = this.canvas.toDataURL();
        if(this.type !== 'return') {
            this.ws.doPost('proposta/salvar/assinatura', {
                id: this.id,
                usuario: this.name,
                imagem: dataUrl,
                tamanho: this.width
            });
        }
        this.ctx = this.canvas.getContext('2d');
        this.clearCanvas();
        let dataToReturn;
        if(this.type == 'return') {
            dataToReturn = {fechou: true,img:dataUrl};
        } else {
            dataToReturn = {fechou:true};
        }
        return this.modalCtrl.dismiss(dataToReturn);
    }
}
