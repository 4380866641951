import {Component} from '@angular/core';
import {SwUpdate} from '@angular/service-worker';

import {MenuController, Platform, ToastController} from '@ionic/angular';
import {StatusBar} from '@ionic-native/status-bar/ngx';
import {NavigationEnd, Router} from '@angular/router';
import {AuthService} from '../services/auth.service';
import {WebserviceService} from '../services/webservice.service';
import {FirebaseX} from "@ionic-native/firebase-x/ngx";
import {NotificationData} from '@ionic-native/fcm';


@Component({
    selector: 'app-root',
    templateUrl: 'app.component.html',
    styles: [`
        .menuItem:not(.online) {
            opacity: 0.3;
        }
    `]
})
export class AppComponent {
    public appPages = [
        {
            title: 'Home',
            url: '/home',
            selected: false
        },
        {
            title: 'Cadastro de Clientes',
            url: '/listaContato/all',
            selected: false
        },
        {
            title: 'Cadastro de Opcionais',
            url: '/lista-acessorios',
            selected: false
        },
        {
            title: 'Grupos de Opcionais',
            url: '/lista-grupo-acessorios',
            selected: false
        },
        {
            title: 'Cadastro de Produtos',
            url: '/lista-produtos',
            selected: false
        },
        {
            title: 'Cadastro de Vendedores',
            url: '/lista-vendedores',
            selected: false
        },
        {
            title: 'Cadastro de Propostas',
            url: '/lista-propostas',
            selected: false,
            offlineOk: true
        },
        {
            title: 'Cadastros de Concessionárias',
            url: '/lista-concessionarias',
            selected: false
        },
        {
            title: 'Pedidos Fechados',
            url: '/lista-pedidos',
            selected: false
        },
        {
            title: 'Relatórios',
            url: '/relatorios',
            selected: false
        },
        {
            title: 'Principais Características de Produtos',
            url: '/lista-caracteristicas',
            selected: false
        },
        {
            title: 'Opções de Pagamento',
            url: '/opcoes-pagamento',
            selected: false
        },
        // {
        //     title: 'Políticas de Preços',
        //     url: '/lista-politica-precos',
        //     selected: false
        // },
        {
            title: 'Políticas de Vendas',
            url: '/politica-vendas',
            selected: false
        },
        // {
        //     title: 'Logout',
        //     url: '/login',
        //     icon: '/assets/icon/exit.png',
        //     selected: false
        // }
        {
            title: 'Aplicação de Produtos',
            url: '/aplicacao-produto',
            selected: false
        }
    ];
    public user = '';
    constructor(
        private platform: Platform,
        private statusBar: StatusBar,
        private menuCtrl: MenuController,
        private router: Router,
        private auth: AuthService,
        public ws: WebserviceService,
        private swUpdate: SwUpdate,
        private fcm: FirebaseX,
        private toastCtrl: ToastController
    ) {
        this.initializeApp();
    }

    initializeApp() {
        if (this.auth.authorized.getValue()) {
            this.auth.getUser().then((user) => {
                this.user = user.nome;
            });
        }
        this.auth.authorized.subscribe((a) => {
            if (a) {
                this.auth.getUser().then((user) => {
                    this.user = user.nome;
                }, () => {
                    this.user = '';
                });
            }
        });
        this.platform.ready().then(async () => {
            this.statusBar.styleDefault();

            if (this.platform.is('cordova')) {
                console.log('Vai inicializar o firebase...');
                try {
                    await this.setupFirebase();
                } catch (e) {
                    console.log("erro ao inicializar firebase", e);
                }
            }
        });

        this.router.events.subscribe((val) => {
            if (val instanceof NavigationEnd) {
                this.appPages.forEach((e) => {
                    if (val.url === e.url) {
                        e.selected = true;
                    } else {
                        e.selected = false;
                    }
                });
            }
        });

        if (!this.swUpdate.isEnabled) return;
        this.swUpdate.available.subscribe(event => {
            setTimeout(() => {
                if (!confirm('Atualização disponível. Recarregar e atualizar?')) return;
                window.location.href = '/';
            });
        });
        this.swUpdate.checkForUpdate().catch();
    }

    public goTo(page) {
        this.menuCtrl.close('sideMenu');
        if (!page.selected) {
            this.router.navigateByUrl(page.url);
        }
    }

    async logout() {
        await this.auth.logout();
        await this.menuCtrl.close('sideMenu');
        await this.router.navigateByUrl('login', {replaceUrl: true});
    }

    setupFirebase() {
        const error = (e: Error) => {
            console.log(e);
            console.log('Erro FCM: ', e.message);
        };
        this.fcm.hasPermission().then(v => {
            console.log(v);
            if (!v) {
                console.log("pede permissao");
                this.fcm.grantPermission().then(r => {
                    console.log('Permitiu');
                });
            }
        });
        console.log('firebase dentro do setup', this.fcm);
        this.fcm.getToken().then(r => {
            console.log('meu token', r);
        });
        this.fcm.subscribe('horsch-notifications');
        this.fcm.onMessageReceived().subscribe(async (data: NotificationData) => {
            this.toastCtrl.create({
                message: data.body, duration: 10000, header: data.title, buttons: [
                    {
                        text: 'Fechar',
                        role: 'cancel',
                        handler: () => {
                            console.log('Cancel clicked');
                        }
                    }
                ]
            }).then((r) => {
                r.present().catch();
            });
        }, error);
    }
}
