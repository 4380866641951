import {Injectable} from '@angular/core';
import {CanActivate} from '@angular/router';
import {AuthService} from './auth.service';
import {WebserviceService} from './webservice.service';

@Injectable({
    providedIn: 'root'
})
/**
 * Não vou renomear, mas é perfil service :)
 */
export class AdminService implements CanActivate {
    constructor(private authService: AuthService, private ws: WebserviceService) {
    }

    canActivate(route, state): Promise<boolean> {
        return this.authService.getUser().then(async (user) => {
            if (user) {
                return await this.verifica_url(route.routeConfig.path);
            } else {
                return false;
            }
        });
    }

    public async verifica_url(path: string) {
        let realFile = '';
        if (await this.ws.isAdmin()) {
            return true;
        }

        switch (path) {
            case 'cadastroContato':
            case 'cadastroContato/:id':
            case 'listaContato/all':
            case 'listaContato/allConce':
                realFile = 'base-view-clientes';
                break;
            case 'lista-propostas':
            case 'cadastro-proposta':
            case 'cadastro-proposta/:id':
            case 'proposta/:id':
                realFile = 'proposta';
                break;
            case 'pedido-banco':
            case 'pedido/:id':
            case 'lista-pedidos':
                realFile = 'pedidos';
                break;
            case 'relatorios':
                realFile = 'relatorio';
                break;
            case 'politica-vendas':
                realFile = 'politica';
                break;
            case 'lista-produtos':
            case 'cadastro-produto':
            case 'cadastro-produto/:id':
                realFile = 'produtos';
                break;
            case 'lista-caracteristicas':
            case 'cadastro-caracteristica':
            case 'cadastro-caracteristica/:id':
                realFile = 'produtos_caracteristicas';
                break;
            case 'lista-acessorios':
            case 'cadastro-acessorio':
            case 'cadastro-acessorio/:id':
                realFile = 'acessorios';
                break;
            case 'lista-grupo-acessorios':
                realFile = 'grupo_acessorios';
                break;
            case 'lista-concessionarias':
            case 'cadastro-concessionaria':
            case 'cadastro-concessionaria/:id':
                realFile = 'concessionaria';
                break;
            case 'lista-vendedores':
            case 'cadastro-vendedores':
            case 'cadastro-vendedores/:id':
                realFile = 'vendedores';
                break;
            case 'lista-politica-precos/:type/:id':
            case 'politica-precos/:type/:id_pai/:id':
            case 'politica-precos/:type/:id_pai':
                realFile = 'config_preco';
                break;
            case 'opcoes-pagamento':
                realFile = 'pagamento';
                break;
            case 'lista-comissoes':
                realFile = 'comissao';
                break;
            case 'lista-grupo-caracteristicas':
                realFile = 'grupo_caracteristicas';
                break;
            case 'lista-configuracoes':
                realFile = 'config_preco';
                break;
            case 'filtro-vendas':
                realFile = 'funil_de_vendas';
                break;
            case 'lista-lotes':
                realFile = 'disponibilidade_de_maquinas';
                break;
            case 'descontos':
                realFile = 'descontos';
                break;
            case 'lista-tabelas':
                realFile = 'tabelas_de_precos';
                break;
            case 'minha-concessionaria':
                realFile = 'minha_concessionaria';
                break;
            case 'aplicacao-produto':
                realFile = 'aplicacao-produto';
                break;
            case 'checklist-pos-vendas':
                realFile = 'preenche-checklists-pos-vendas';
                break;
            case 'qualidades/checklist':
                realFile = 'preenche-checklists-qualidade';
                break;
            case 'checklist-pos-vendas/relatorio':
                realFile = 'pv-status-checklist';
                break;
            case 'ativacao-pos-vendas':
                realFile = 'pv-solicita-ativacao-monitor';
                break;
            case 'suporte':
                realFile = 'sv-pedido-suporte';
                break;
            case 'maquinas-qualidade':
                realFile = 'ql-preenche-checklist-montagem-maquinas';
                break;
            default:
                return true;
        }

        const list = await this.ws.getPermissionList();
        if (!list) {
            return false;
        }

        return list.indexOf(realFile) >= 0;
    }
}
