export const AppConfig = {
  // backend é controlado pelo AmbienteService
  backend: getBackend(true),
  backendV2: getBackendV2(),
  cdn: '',
  debug: false,
  ufs:[
  {nome: "Acre", sigla: "AC"},
  {nome: "Alagoas", sigla: "AL"},
  {nome: "Amapá", sigla: "AP"},
  {nome: "Amazonas", sigla: "AM"},
  {nome: "Bahia", sigla: "BA"},
  {nome: "Ceará", sigla: "CE"},
  {nome: "Distrito Federal", sigla: "DF"},
  {nome: "Espírito Santo", sigla: "ES"},
  {nome: "Goiás", sigla: "GO"},
  {nome: "Maranhão", sigla: "MA"},
  {nome: "Mato Grosso", sigla: "MT"},
  {nome: "Mato Grosso do Sul", sigla: "MS"},
  {nome: "Minas Gerais", sigla: "MG"},
  {nome: "Pará", sigla: "PA"},
  {nome: "Paraíba", sigla: "PB"},
  {nome: "Paraná", sigla: "PR"},
  {nome: "Pernambuco", sigla: "PE"},
  {nome: "Piauí", sigla: "PI"},
  {nome: "Rio de Janeiro", sigla: "RJ"},
  {nome: "Rio Grande do Norte", sigla: "RN"},
  {nome: "Rio Grande do Sul", sigla: "RS"},
  {nome: "Rondônia", sigla: "RO"},
  {nome: "Roraima", sigla: "RR"},
  {nome: "Santa Catarina", sigla: "SC"},
  {nome: "São Paulo", sigla: "SP"},
  {nome: "Sergipe", sigla: "SE"},
  {nome: "Tocantins", sigla: "TO"}
]
};

export function getBackendV2() {
  switch (window.location.host) {
    case 'horsch.tuxon.com.br':
      return 'https://horsch-v2.tuxon.com.br/api';
    case 'localhost:4200':
    case 'localhost:8100':
      return 'http://localhost:82/api';
    default:
      return 'https://horsch-v2.tuxon.com.br/api';
  }
}

export function getBackend(producao: boolean) {
  if(producao) {
    switch (window.location.host) {
      case 'horsch.tuxon.com.br':
        return 'http://horsch-v1.dev.tuxon.com.br/ws';
      case 'localhost:4200':
      case 'localhost:8100':
        return 'http://localhost:82/horsch_ws/ws';
      default:
        return 'https://backend-app.horsch.com.br/ws/index.php';
    }
  } else {
    switch (window.location.host) {
      case 'horsch.tuxon.com.br':
        return 'http://horsch-v1.dev.tuxon.com.br/ws';
      case 'localhost:4200':
      case 'localhost:8100':
        return 'http://localhost/horsch_ws/ws';
      default:
        return 'https://backend-homolog-app.horsch.com.br/ws/index.php';
    }
  }

}


export const PerfisV2 = [
  'soldador-qualidades',
  'montador-qualidades',
  'tecnico-qualidades',
  'Técnico pós-vendas',
  'administrador-qualidade',
  'admin',
  'administrador-pos-vendas',
  'pv-pecas',
  'comprador-concessionário',
  'usuario-horsch',
];
