import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {AppConfig} from '../app/app.config';
import {AuthService} from './auth.service';
import {AlertController, Events} from '@ionic/angular';

export interface Endereco {
    cidade: string;
    uf: string;
    logradouro: string;
    bairro: string;
    erro: string;
}

@Injectable()
export class ConsultacepService {

    constructor(
        private http: HttpClient,
        private authService: AuthService,
        private alertCtrl: AlertController,
        public events: Events
    ) {
    }

    public async consulta(cep: string) {
        cep = cep.replace(/\D/g, '');
        const debug = !AppConfig.debug ? '' : '?XDEBUG_SESSION_START=1';
        return this.http.get<Endereco>(
            `${AppConfig.backend}/consultacep/procurar/${cep}${debug}`,
            {headers: await this.authService.getHeadersWithAuth()}).toPromise();
    }

}
