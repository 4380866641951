import {APP_INITIALIZER, LOCALE_ID, NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {Router, RouteReuseStrategy} from '@angular/router';
import {FileOpener} from "@ionic-native/file-opener/ngx";
import {FileTransfer} from "@ionic-native/file-transfer/ngx";

import {
    AlertController,
    IonicModule,
    IonicRouteStrategy,
    LoadingController, MenuController,
    NavController,
    Platform, ToastController
} from '@ionic/angular';
import {StatusBar} from '@ionic-native/status-bar/ngx';
import {DownloadService} from "../services/download.service";

import {AppComponent} from './app.component';
import {AppRoutingModule} from './app-routing.module';
import {AuthService} from '../services/auth.service';
import {IonicStorageModule, Storage} from '@ionic/storage';
import {HttpClient, HttpClientModule} from '@angular/common/http';
import {InteressadoService} from '../services/interessado.service';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {NotAuthService} from '../services/not_auth.service';
import {Contacts} from '@ionic-native/contacts/ngx';
import {OpenNativeSettings} from '@ionic-native/open-native-settings/ngx';
import { File } from "@ionic-native/file/ngx";
import {WebserviceService} from '../services/webservice.service';
import {Camera} from '@ionic-native/camera/ngx';
import ptBr from '@angular/common/locales/pt';
import {registerLocaleData} from '@angular/common';
import {ConsultacepService} from '../services/consultacep.service';
import {Network} from '@ionic-native/network/ngx';
import {Network as AwesomeNetwork} from '@awesome-cordova-plugins/network/ngx';

import {AssinaturaModalPage} from './assinatura-modal/assinatura-modal.page';
import {AssinaturaModalPageModule} from './assinatura-modal/assinatura-modal.module';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import {AmbienteService} from '../services/ambiente.service';
import {IonicSelectableModule} from 'ionic-selectable';
import {AplicacaoProdutoService} from "../services/aplicacao-produto.service";
import {NgxMaskIonicModule} from "ngx-mask-ionic";
import {WebV2Service} from "../services/web-v2.service";
import {FirebaseX} from "@ionic-native/firebase-x/ngx";
import { NgSelectModule } from '@ng-select/ng-select';
registerLocaleData(ptBr);
@NgModule({
    declarations: [AppComponent],
    entryComponents: [AssinaturaModalPage],
    imports: [
        BrowserModule,
        IonicModule.forRoot(),
        IonicStorageModule.forRoot(),
        AppRoutingModule,
        HttpClientModule,
        FormsModule,
        ReactiveFormsModule,
        AssinaturaModalPageModule,
        FontAwesomeModule,
        IonicSelectableModule,
        NgxMaskIonicModule.forRoot(),
        ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production })
    ],
    providers: [
        StatusBar,
        AuthService,
        NotAuthService,
        InteressadoService,
        {provide: RouteReuseStrategy, useClass: IonicRouteStrategy},
        { provide: LOCALE_ID, useValue: 'pt-PT' },
        Contacts,
        OpenNativeSettings,
        WebserviceService,
        Camera,
        ConsultacepService,
        Network,
        AwesomeNetwork,
        FirebaseX,
        FileTransfer,
        FileOpener,
        File,
        DownloadService
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
    constructor() {
    }
}
