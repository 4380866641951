import {NgModule} from '@angular/core';
import {PreloadAllModules, RouterModule, Routes} from '@angular/router';
import {AuthService} from '../services/auth.service';
import {NotAuthService} from '../services/not_auth.service';
import {AdminService} from '../services/admin.service';

const routes: Routes = [
    {
        path: '',
        redirectTo: 'login',
        pathMatch: 'full'
    },
    {
        path: 'cadastroContato',
        loadChildren: './cadastroContato/cadastroContato.module#CadastroContatoModule',
        canActivate: [AuthService, AdminService],
    },
    {
        path: 'cadastroContato/:id',
        loadChildren: './cadastroContato/cadastroContato.module#CadastroContatoModule',
        canActivate: [AuthService, AdminService]
    },
    {
        path: 'listaContato/:tipo',
        loadChildren: './listaContato/listaContato.module#ListaContatoModule',
        canActivate: [AuthService, AdminService]
    },
    {
        path: 'login',
        loadChildren: './login/login.module#LoginPageModule',
        canActivate: [NotAuthService],
    },
  { path: 'relatorios', loadChildren: './relatorios/relatorios.module#RelatoriosPageModule',
  canActivate: [AdminService,AuthService]},
  { path: 'opcoes-pagamento', loadChildren: './opcoes-pagamento/opcoes-pagamento.module#OpcoesPagamentoPageModule',
  canActivate: [AdminService,AuthService]},
  {
    path: 'lista-politica-precos/:type/:id',
    loadChildren: './lista-politica-precos/lista-politica-precos.module#ListaPoliticaPrecosPageModule',
    canActivate: [AuthService, AdminService]
  },
  {
    path: 'politica-precos/:type/:id_pai/:id',
    loadChildren: './politica-precos/politica-precos.module#PoliticaPrecosPageModule',
    canActivate: [AuthService, AdminService]
  },
  {
    path: 'politica-precos/:type/:id_pai',
    loadChildren: './politica-precos/politica-precos.module#PoliticaPrecosPageModule',
    canActivate: [AuthService, AdminService]
  },
  {
    path: 'politica-vendas',
    loadChildren: './politica-vendas/politica-vendas.module#PoliticaVendasPageModule',
    canActivate: [AuthService, AdminService] },
  {
    path: 'lista-concessionarias',
    loadChildren: './lista-concessionarias/lista-concessionarias.module#ListaConcessionariasPageModule',
    canActivate: [AuthService, AdminService]},
  {
    path: 'cadastro-concessionaria',
    loadChildren: './cadastro-concessionaria/cadastro-concessionaria.module#CadastroConcessionariaPageModule',
    canActivate: [AuthService, AdminService]
  }, {
    path: 'cadastro-concessionaria/:id',
    loadChildren: './cadastro-concessionaria/cadastro-concessionaria.module#CadastroConcessionariaPageModule',
    canActivate: [AuthService, AdminService]
  },
  // { path: 'lista-coordenadores', loadChildren: './lista-coordenadores/lista-coordenadores.module#ListaCoordenadoresPageModule' },
  // { path: 'cadastro-coordenador', loadChildren: './cadastro-coordenador/cadastro-coordenador.module#CadastroCoordenadorPageModule' },
  {
    path: 'lista-vendedores',
    loadChildren: './lista-vendedores/lista-vendedores.module#ListaVendedoresPageModule',
    canActivate: [AuthService, AdminService]
  },
  {
    path: 'cadastro-vendedor',
    loadChildren: './cadastro-vendedor/cadastro-vendedor.module#CadastroVendedorPageModule',
    canActivate: [AuthService, AdminService]
  },
  {
    path: 'cadastro-vendedor/:id',
    loadChildren: './cadastro-vendedor/cadastro-vendedor.module#CadastroVendedorPageModule',
    canActivate: [AuthService, AdminService]
  },
  {
    path: 'lista-caracteristicas',
    loadChildren: './lista-caracteristicas/lista-caracteristicas.module#ListaCaracteristicasPageModule',
    canActivate: [AuthService, AdminService]
  },
  {
    path: 'cadastro-caracteristica',
    loadChildren: './cadastro-caracteristica/cadastro-caracteristica.module#CadastroCaracteristicaPageModule',
    canActivate: [AuthService, AdminService]
  },
  {
    path: 'filtro-vendas',
      loadChildren: './filtro-vendas/filtro-vendas.module#FiltroVendasPageModule',
      canActivate: [AuthService, AdminService]
  },
  {
    path: 'cadastro-caracteristica/:id',
    loadChildren: './cadastro-caracteristica/cadastro-caracteristica.module#CadastroCaracteristicaPageModule',
    canActivate: [AuthService, AdminService]
  },
  // { path: 'lista-variacoes', loadChildren: './lista-variacoes/lista-variacoes.module#ListaVariacoesPageModule' },
  // { path: 'cadastro-variacao', loadChildren: './cadastro-variacao/cadastro-variacao.module#CadastroVariacaoPageModule' },
  { path: 'lista-acessorios', loadChildren: './lista-acessorios/lista-acessorios.module#ListaAcessoriosPageModule', canActivate: [AuthService, AdminService] },
  { path: 'cadastro-acessorio', loadChildren: './cadastro-acessorio/cadastro-acessorio.module#CadastroAcessorioPageModule', canActivate: [AuthService, AdminService] },
  { path: 'cadastro-acessorio/:id', loadChildren: './cadastro-acessorio/cadastro-acessorio.module#CadastroAcessorioPageModule', canActivate: [AuthService, AdminService] },
  { path: 'lista-grupo-acessorios', loadChildren: './lista-grupo-acessorios/lista-grupo-acessorios.module#ListaGrupoAcessoriosPageModule', canActivate: [AuthService, AdminService] },
  { path: 'cadastro-grupo-acessorios', loadChildren: './cadastro-grupo-acessorios/cadastro-grupo-acessorios.module#CadastroGrupoAcessoriosPageModule', canActivate: [AuthService, AdminService] },
  { path: 'cadastro-grupo-acessorios/:id', loadChildren: './cadastro-grupo-acessorios/cadastro-grupo-acessorios.module#CadastroGrupoAcessoriosPageModule', canActivate: [AuthService, AdminService] },
  { path: 'lista-produtos', loadChildren: './lista-produtos/lista-produtos.module#ListaProdutosPageModule', canActivate: [AuthService, AdminService] },
  { path: 'cadastro-produto', loadChildren: './cadastro-produto/cadastro-produto.module#CadastroProdutoPageModule', canActivate: [AuthService, AdminService] },
  { path: 'cadastro-produto/:id', loadChildren: './cadastro-produto/cadastro-produto.module#CadastroProdutoPageModule', canActivate: [AuthService, AdminService] },
  { path: 'pedido-banco', loadChildren: './pedido-banco/pedido-banco.module#PedidoBancoPageModule', canActivate: [AuthService, AdminService] },
  { path: 'pedido/:id', loadChildren: './pedido/pedido.module#PedidoPageModule', canActivate: [AuthService, AdminService] },
  { path: 'lista-pedidos', loadChildren: './lista-pedidos/lista-pedidos.module#ListaPedidosPageModule', canActivate: [AuthService, AdminService] },
  { path: 'lista-propostas', loadChildren: './lista-propostas/lista-propostas.module#ListaPropostasPageModule', canActivate: [AuthService, AdminService] },
  { path: 'lista-propostas/:tipo', loadChildren: './lista-propostas/lista-propostas.module#ListaPropostasPageModule', canActivate: [AuthService, AdminService] },
  { path: 'cadastro-proposta', loadChildren: './cadastro-proposta/cadastro-proposta.module#CadastroPropostaPageModule', canActivate: [AuthService, AdminService] },
  { path: 'cadastro-proposta/:id', loadChildren: './cadastro-proposta/cadastro-proposta.module#CadastroPropostaPageModule', canActivate: [AuthService, AdminService] },
  { path: 'proposta/:id', loadChildren: './proposta/proposta.module#PropostaPageModule', canActivate: [AuthService, AdminService] },
  // { path: 'lista-clientes', loadChildren: './lista-clientes/lista-clientes.module#ListaClientesPageModule' },
  // { path: 'cadastro-cliente', loadChildren: './cadastro-cliente/cadastro-cliente.module#CadastroClientePageModule' },
  // { path: 'cadastro-contato', loadChildren: './cadastro-contato/cadastro-contato.module#CadastroContatoPageModule' },
  { path: 'home', loadChildren: './home/home.module#HomePageModule', canActivate: [AuthService] },
  { path: 'assinatura-modal', loadChildren: './assinatura-modal/assinatura-modal.module#AssinaturaModalPageModule', canActivate: [AuthService] },
  { path: 'lista-comissoes', loadChildren: './lista-comissoes/lista-comissoes.module#ListaComissoesPageModule', canActivate: [AuthService, AdminService] },
  { path: 'lista-grupo-caracteristicas', loadChildren: './lista-grupo-caracteristicas/lista-grupo-caracteristicas.module#ListaGrupoCaracteristicasPageModule', canActivate: [AuthService, AdminService] },
  { path: 'cadastro-grupo-caracteristicas', loadChildren: './cadastro-grupo-caracteristicas/cadastro-grupo-caracteristicas.module#CadastroGrupoCaracteristicasPageModule', canActivate: [AuthService, AdminService] },
  { path: 'cadastro-grupo-caracteristicas/:id', loadChildren: './cadastro-grupo-caracteristicas/cadastro-grupo-caracteristicas.module#CadastroGrupoCaracteristicasPageModule', canActivate: [AuthService, AdminService] },
  { path: 'cadastro-configuracao', loadChildren: './cadastro-configuracao/cadastro-configuracao.module#CadastroConfiguracaoPageModule', canActivate: [AuthService, AdminService] },
  { path: 'cadastro-configuracao/:id', loadChildren: './cadastro-configuracao/cadastro-configuracao.module#CadastroConfiguracaoPageModule', canActivate: [AuthService, AdminService] },
  { path: 'lista-configuracoes', loadChildren: './lista-configuracoes/lista-configuracoes.module#ListaConfiguracoesPageModule', canActivate: [AuthService, AdminService] },
  { path: 'lista-lotes', loadChildren: './lista-lotes/lista-lotes.module#ListaLotesPageModule', canActivate: [AuthService, AdminService]},
  { path: 'lista-tabelas', loadChildren: './lista-tabelas/lista-tabelas.module#ListaTabelasPageModule', canActivate: [AuthService, AdminService]},
  { path: 'cadastro-lote', loadChildren: './cadastro-lote/cadastro-lote.module#CadastroLotePageModule', canActivate: [AuthService, AdminService]},
  { path: 'cadastro-tabela', loadChildren: './cadastro-tabela/cadastro-tabela.module#CadastroTabelaPageModule', canActivate: [AuthService, AdminService]},
  { path: 'cadastro-tabela/:id', loadChildren: './cadastro-tabela/cadastro-tabela.module#CadastroTabelaPageModule', canActivate: [AuthService, AdminService]},
  { path: 'cadastro-tabela/copy/:id', loadChildren: './cadastro-tabela/cadastro-tabela.module#CadastroTabelaPageModule', canActivate: [AuthService, AdminService]},
  { path: 'filtro-vendas', loadChildren: './filtro-vendas/filtro-vendas.module#FiltroVendasPageModule' },
  { path: 'troca-senha', loadChildren: './troca-senha/troca-senha.module#TrocaSenhaPageModule' },
  { path: 'descontos', loadChildren: './descontos/descontos.module#DescontosPageModule' },
  { path: 'minha-concessionaria', loadChildren: './minha-concessionaria/minha-concessionaria.module#MinhaConcessionariaModule' },
  { path: 'cadastro-fazenda/:id', loadChildren: './cadastro-fazenda/cadastro-fazenda.module#CadastroFazendaPageModule', canActivate: [AuthService, AdminService]},
  { path: 'cadastro-fazenda/:id/fazenda/:fazenda', loadChildren: './cadastro-fazenda/cadastro-fazenda.module#CadastroFazendaPageModule', canActivate: [AuthService, AdminService]},
  { path: 'lista-fazenda/:id', loadChildren: './lista-fazenda/lista-fazenda.module#ListaFazendaPageModule', canActivate: [AuthService, AdminService]},
  { path: 'aplicacao-produto', loadChildren: './aplicacao-produto/aplicacao-produto.module#AplicacaoProdutoPageModule' },
  { path: 'checklist-pos-vendas', loadChildren: './checklist/checklist.module#ChecklistPageModule' },
  { path: 'checklist-pos-vendas-preencher', loadChildren: './checklist-preencher/checklist-preencher.module#ChecklistPreencherPageModule' },
  { path: 'checklist-pos-vendas/relatorio', loadChildren: './checklist/relatorio/checklist-relatorio.module#ChecklistRelatorioPageModule' },
  { path: 'ativacao-pos-vendas', loadChildren: './ativacao-pos-vendas/ativacao-pos-vendas.module#AtivacaoPosVendasPageModule' },
  { path: 'ativacao-pos-vendas-visualizar/:id', loadChildren: './ativacao-pos-vendas-visualizar/ativacao-pos-vendas-visualizar.module#AtivacaoPosVendasVisualizarPageModule' },
  { path: 'qualidades/checklist', loadChildren: './qualidades/checklist/checklist.module#ChecklistPageModule' },
  { path: 'qualidades/checklist-preenche', loadChildren: './qualidades/checklist-preenche/checklist-preenche.module#ChecklistPreenchePageModule' },
  { path: 'maquinas-qualidade', loadChildren: './maquinas-qualidade/maquinas-qualidade.module#MaquinasQualidadePageModule' },
  { path: 'maquinas-qualidade/checklist-preenche', loadChildren: './maquinas-qualidade/checklist-preenche/checklist-preenche.module#ChecklistPreenchePageModule' },
  // { path: 'pre-entrega-tab', loadChildren: './pre-entrega-tab/pre-entrega-tab.module#PreEntregaTabPageModule' },
  // { path: 'recebimento-tab', loadChildren: './recebimento-tab/recebimento-tab.module#RecebimentoTabPageModule' },
  // { path: 'entrega-tecnica-tab', loadChildren: './entrega-tecnica-tab/entrega-tecnica-tab.module#EntregaTecnicaTabPageModule' },
  { path: 'suporte', loadChildren: './suporte/suporte.module#SuportePageModule' },
  { path: 'chat', loadChildren: './suporte/chat/chat.module#ChatPageModule' },
  { path: 'acompanhamento', loadChildren: './suporte/acompanhamento/acompanhamento.module#AcompanhamentoPageModule' },

];

@NgModule({
    imports: [
        RouterModule.forRoot(routes) // , {preloadingStrategy: PreloadAllModules}
    ],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
