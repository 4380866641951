import {ApplicationRef, Injectable} from "@angular/core";
import {HttpClient, HttpErrorResponse} from "@angular/common/http";
import {Network} from "@ionic-native/network/ngx";
import {BehaviorSubject, Observable, of} from 'rxjs';
import {catchError, switchMap, tap, timeout} from "rxjs/operators";
import {AppConfig, PerfisV2} from '../app/app.config';
import {AlertController, LoadingController, MenuController, Platform} from '@ionic/angular';
import {AuthService} from './auth.service';
import {Storage} from '@ionic/storage';
import {Router} from "@angular/router";

@Injectable()
export class WebserviceService {
  // url de backend na inicializacao de serviço tem que ficar em uma função
  public get backend() { return AppConfig.backend + '/'; }
  public get backendV2() { return AppConfig.backendV2 + '/'; }
  readonly debug = AppConfig.debug;
  get online$() { return this.onlineSubj.asObservable(); }
  get online() { return this.onlineSubj.getValue(); }

  private loadPresented = 0;
  private loader: Promise<HTMLIonLoadingElement>;
  private onlineSubj = new BehaviorSubject(false);
  constructor(protected http: HttpClient,
              protected alertController: AlertController,
              protected loadingController: LoadingController,
              protected sessionProvider: AuthService,
              protected storage: Storage,
              private menuCtrl: MenuController,
              private router: Router,
              private auth: AuthService,
              network: Network,
              platform: Platform,
              app: ApplicationRef) {
    this.loader = this.loadingController.create({message: 'Aguarde...'});
    platform.ready().then(() => {
      const nextVal = network.type !== 'none';
      if (this.onlineSubj.getValue() !== nextVal) this.onlineSubj.next(nextVal);
      const onchange = network.onChange();
      onchange && onchange.subscribe(() => this.onlineSubj.next(network.type !== 'none'));
    });

  }

  async createLoader() {
    if (this.loadPresented === 0) {
      this.loader = this.loadingController.create({message: 'Aguarde...'});
      await this.loader;
    }
  }

  async doGet<T = any>(endpoint: string, alert = true) {
    alert && await this.createLoader();
    const session = await this.sessionProvider.getUser();
    if (session === null || session.token === '') {
      return await this.wrap<T>(this.http.get<T>(this.buildUrl(endpoint)),alert).toPromise();
    }

    let head: any = {Authorization: 'Bearer '+session.token};

    return await this.wrap<T>(this.http.get<T>(this.buildUrl(endpoint),{headers: head}),alert).toPromise();
  }

  async doGetBlob<Blob = any>(endpoint: string, alert = true, v2 = false, keepUrl = false) {
    alert && await this.createLoader();
    const session = await this.sessionProvider.getUser();
    if (session === null || session.token === '') {
      return await this.wrap<Blob>(this.http.get<Blob>(this.buildUrl(endpoint, keepUrl)),alert).toPromise();
    }
    let head: any = {'php-auth-pw': session.token};

    if(v2) {
      head = {Authorization: 'Bearer '+session.token};
    }
    return await this.wrap<Blob>(this.http.get<Blob>(this.buildUrl(endpoint, keepUrl),{headers: head, responseType: 'blob' as 'json'}),alert).toPromise();
  }

  async doPost<T = any, U = any>(endpoint: string, params: U, alert = true) {
    alert && await this.createLoader();
    const session = await this.sessionProvider.getUser();
    if (session === null || session.token === '') {
      return this.wrap<T>(this.http.post<T>(this.buildUrl(endpoint), params),alert).toPromise();
    }

    let head: any = {Authorization: 'Bearer '+session.token};

    return await this.wrap<T>(this.http.post<T>(this.buildUrl(endpoint), params, {headers: head}),alert).toPromise();
  }

  async doPut<T = any, U = any>(endpoint: string, params: U, alert = true) {
    alert && await this.createLoader();
    const session = await this.sessionProvider.getUser();
    if (session === null || session.token === '') {
      return this.wrap<T>(this.http.put<T>(this.buildUrl(endpoint), params),alert).toPromise();
    }
    return await this.wrap<T>(this.http.put<T>(this.buildUrl(endpoint), params, {headers: await this.sessionProvider.getHeadersWithAuth()}),alert).toPromise();
  }

  async doDelete<T = any>(endpoint: string, alert = true) {
    alert && await this.createLoader();
    const session = await this.sessionProvider.getUser();
    if (session === null || session.token === '') {
      return this.wrap<T>(this.http.delete<T>(this.buildUrl(endpoint)),alert).toPromise();
    }

    const head = { Authorization: 'Bearer '+session.token };

    return await this.wrap<T>(this.http.delete<T>(this.buildUrl(endpoint), {headers: head}), alert).toPromise();
  }

  protected wrap<T>(call$: Observable<T>,alert = true) {
    return of({}).pipe(
        tap(() => {
          alert && this.carregando(true);
        }),
        switchMap(() => {
          return call$.pipe(
              timeout(60000),
              catchError((e) => {
                if (e instanceof HttpErrorResponse) {
                  const message = e.error.erro || `Falha de comunicação`;
                  console.log(e);
                  if(e.status === 403 && e.error.includes('Token inválido')) {
                      this.auth.logout();
                      this.menuCtrl.close('sideMenu');
                      this.router.navigateByUrl('login', {replaceUrl: true});
                  }
                  if (alert) {
                    this.alertController.create({
                      header: 'Erro',
                      message,
                      buttons: ['OK']
                    }).then((res) => {
                      res.present().catch();
                    });
                  }
                }
                return of(undefined);
              }),
          );
        }),
        tap(() => {
          alert && this.carregando(false);
        })
    );
  }

  private buildUrl(endpoint: string, keepUrl = false) {
    if(keepUrl)
      return endpoint;
    let url = (this.backendV2) + endpoint;

    if (this.debug) {
      url += url.indexOf('?') === -1 ? '?' : '&';
      url += 'XDEBUG_SESSION_START=1';
    }

    return url;
  }

  async carregando(b: boolean) {
    this.loadPresented += b ? 1 : -1;
    // console.log(b,this.loadPresented);
    if (this.loadPresented == 0) {
      (await this.loader).dismiss().catch();
    } else {
      await this.createLoader();
      (await this.loader).present().catch();
    }
  }
  private async getPerfil() {
    let permissoes = await this.storage.get('perfil');

    const user = await this.storage.get('user');
    if(true || (PerfisV2.includes(user.perfil_slug as string))) {
      const perfilV2 = {permissoes: user.permissions, per_adm: false};
      await this.storage.set('perfil',
          {permissoes: user.permissions, per_adm: false});
      await this.storage.set('perfil_data',new Date().getTime());
      return perfilV2;
    }
    if (permissoes) {
      const data = await this.storage.get('perfil_data');
      if (data < new Date().getTime() - (10*60*1000)) { // 10 minuto de cache só pra nao spammar! :)
        const temp = await this.recarregaPerfil();
        permissoes = temp ? temp : permissoes;
      }
    } else {
      permissoes = await this.recarregaPerfil();
    }
    return permissoes ? permissoes : null;
  }
  async getPermissionList(): Promise<string[]> {
    const perfil = await this.getPerfil();
    return perfil ? perfil.permissoes : [];
  }

  private async recarregaPerfil() {
    const perfil = await this.doGet('usuario/perfil', false);
    if (perfil) {
      await this.storage.set('perfil',perfil);
      await this.storage.set('perfil_data',new Date().getTime());
    }
    return perfil;
  }

  async isAdmin() {
    const perfil = await this.getPerfil();
    return perfil ? perfil.per_adm : false;
  }
}
