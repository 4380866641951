import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {AppConfig} from '../app/app.config';
import {Storage} from '@ionic/storage';
import {CanActivate, Router} from '@angular/router';
import {AlertController, Platform} from '@ionic/angular';
import {BehaviorSubject} from 'rxjs';
import {FirebaseX} from "@ionic-native/firebase-x/ngx";


export interface Usuario {
    permissions?: Array<string>;
    perfil_slug?: string;
    changePass: boolean;
    id: string;
    nome: string;
    email: string;
    foto: string;
    token: string;
    perfil: string;
    codigo: string;
    api?: null|string;
}


@Injectable({
    providedIn: 'root'
})
export class AuthService implements CanActivate {

    protected storedTokenAsync: Promise<string | false>;
    // url de backend na inicializacao de serviço tem que ficar em uma função
    public get backend() { return AppConfig.backend; }
    public get backendV2() { return AppConfig.backendV2; }
    protected tokenKey = 'horsch:token';
    public authorized = new BehaviorSubject(false);
    constructor(protected storage: Storage,
                protected http: HttpClient,
                public router: Router,
                private alertCtrl: AlertController,
                private fcm: FirebaseX,
                private platform: Platform
    ) {
        this.storedTokenAsync = this.getToken();
    }

    async getHeadersWithAuth(previous?: HttpHeaders | null, token?: string) {
        previous = previous || new HttpHeaders();
        const myToken = token || await this.storedTokenAsync;
        const b64 = btoa(`:${myToken}`);
        return previous.set('Authorization', `Basic ${b64}`);

    }

    protected async getToken() {
        /*
            Verifica se há token..
         */
        const token: string = await this.storage.get(this.tokenKey).catch(() => '');
        if (token) {
            return token;
        } else {
            return false;
        }
    }

    protected async setToken(token: string) {
        await this.storage.set(this.tokenKey, token).catch(() => '');
    }
    public async getUser(): Promise<Usuario> {
        return await this.storage.get('user') as Usuario;
    }

    public async setUser(user: Usuario) {
        await this.storage.set('user',user);
    }

    /**
     * Retorna o perfil_slug do usuário logado
     * @return Usuario
     */
    public async getUserProfile(): Promise<any> {
        return (await this.getUser()).perfil_slug;
    }

    /**
     * Verifica se o usuário possui o perfil_slug inserido
     * @param profile
     */
    public async isUserProfile(profile: string){
        return ((await this.getUserProfile()) == profile);
    }

    /**
     * Verifica se o usuário possui permissão informado
     */
    public async hasPermission(permission: string){
        // @ts-ignore
        return (await this.getUser()).permissions.includes(permission);
    }

    public async getPermissions(){
        return (await this.getUser()).permissions;
    }

    async requestToken(usuario: string, senha: string) {
        return await this.requestTokenV2(usuario, senha);
        // tenta direto no v2, desabilitando essa opção aqui até segunda ordem
        const debug = !AppConfig.debug ? '' : '?XDEBUG_SESSION_START=1';
        try {
            const ret = await this.http.post<{ token: string }>(
                `${this.backend}/usuario/${debug}`, {usuario, senha}).toPromise();
            console.log(this.backend);
            if (ret.token) {
                await this.storage.set('user',ret);
                this.authorized.next(true);
            }
            return ret.token;
        } catch (e) {
            // falhou no antigo, tenta no novo
            return await this.requestTokenV2(usuario, senha);
        }

    }

    async requestTokenV2(login: string, password: string) {
        // check if platform is cordova
        let firebaseToken: any = null;
        if (this.platform.is('cordova')) {
            firebaseToken = await this.fcm.getToken();
        }
        const ret = await this.http.post<{api_token: string}>(
            `${this.backendV2}/login`, {login, password, firebase_token: firebaseToken}
        ).toPromise();

        if(ret.api_token) {
            await this.storage.set('user',ret);
            this.authorized.next(true);
        }

        return ret.api_token;
    }

    async isLogado() {
        const wasAuthorized = this.authorized.getValue();
        return await this.getUser().then((r) => {
            if (r && r.codigo) {
                if (!wasAuthorized) { this.authorized.next(true); }
                this.storedTokenAsync = this.getToken();
                return true;
            } else {
                if (wasAuthorized) { this.authorized.next(false); }
                return false;
            }
        });
    }

    async logout() {
        await this.storage.clear();
        this.authorized.next(false);
    }

    async canActivate() {
        if (!(await this.isLogado())) {
            this.router.navigate(['login'], {replaceUrl: true});
            return false;
        } else {
            const user = await this.getUser();
            if (user.changePass) {
                this.router.navigate(['troca-senha']);
                return false;
            }
            return true;
        }
    }

    async logar(login: string, senha: string) {
        try {
            const token = await this.requestToken(login, senha);
            await this.setToken(token);
            await this.router.navigate(['home'], {replaceUrl: true});
        } catch (e) {
            let message = '';
            if (typeof e.error === 'string') {
                message = e.error;
            } else {
                message = 'Falha na comunicação. Tente mais tarde.';
            }
            const erro = await this.alertCtrl.create({
                header: 'Erro',
                message,
                buttons: ['OK']
            });
            await erro.present();
            return;
        }
    }

    async resetPass(email: string) {
        try {

            const ret = await this.http.post<{ message: string }>(
                `${this.backend}/usuario/esqueceu-senha`, {email}).toPromise();

            if(ret.message === 'Email não cadastrado') {
                // tenta no v2
                const retV2 = await this.http.post(
                    `${this.backendV2}/reset-password`,{email}
                ).toPromise();
            }
            return ret.message;
        } catch (e) {
            let message = '';
            if (typeof e.error === 'string') {
                message = e.error;
            } else {
                message = 'Falha na comunicação. Tente mais tarde.';
            }
            const erro = await this.alertCtrl.create({
                header: 'Erro',
                message,
                buttons: ['OK']
            });
            await erro.present();
            return;
        }
    }
}
