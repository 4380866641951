import {Injectable} from '@angular/core';
import {File, FileEntry} from "@ionic-native/file";
import {FileOpener} from "@ionic-native/file-opener/ngx";
import {FileTransfer} from "@ionic-native/file-transfer/ngx";
import {AlertController, LoadingController} from "@ionic/angular";
import {AppConfig} from "../app/app.config";

declare namespace BackgroundTransfer {
    class BackgroundDownloader {
      createDownload(remote: string, local: FileEntry): Download;
    }
    interface Download {
      startAsync(): InnerPromise;
    }
    interface InnerPromise {
      then(onSuccess: () => void, onError: () => void, onProgress: () => void);
    }
}

@Injectable({
  providedIn: 'root'
})
export class DownloadService {
  constructor(
    private alertCtrl: AlertController,
    private loadingCtrl: LoadingController,
    private transfer: FileTransfer,
    private fileOpener: FileOpener
  ) {}

  async do(getUrl: () => Promise<string>) {
    const loading = await this.loadingCtrl.create({
      message: 'Carregando...'
    });
    await loading.present();
    try {
      const url = await getUrl();
      if (!url) return;

      const fullUrl = AppConfig.backend.replace(/\/ws.*/, url);
      if (!window['cordova']) {
        console.log(fullUrl);
        window.open(fullUrl);
        return;
      }

      let filename = url.replace(/.*\//, '');
      //caso a url tenha parametros
      filename = filename.split('?')[0];

      const fileTransfer = this.transfer.create();
      let entry: any;
      try {
        entry = await fileTransfer.download(fullUrl, File.dataDirectory + filename, true);
      } catch (e) {
        await (await this.alertCtrl.create({
          header: 'Ocorreu um erro!',
          message: 'Erro ao baixar o arquivo. Favor tentar novamente mais tarde.',
          buttons: ['OK']
        })).present();
        return;
      }

      try {
        let mime = 'application/octet-stream';
        if (filename.match(/\.pdf$/)) mime = 'application/pdf';
        else if (filename.match(/\.xls$/)) mime = 'application/vnd.ms-excel';
        await this.fileOpener.open(entry.toURL(), mime);
      } catch (e) {
        await (await this.alertCtrl.create({
          header: 'Ocorreu um erro!',
          message: 'Erro ao abrir o arquivo. Favor tentar novamente mais tarde.',
          buttons: ['OK']
        })).present();
        return;
      }
    } finally {
      await loading.dismiss();
    }
  }

  async doAlternative(getUrl: () => Promise<string>) {
    const loading = await this.loadingCtrl.create({
      message: 'Carregando...'
    });
    await loading.present();
    try {
      const url = await getUrl();
      if (!url) return;

      const fullUrl = AppConfig.backend.replace(/\/ws.*/, url);
      window.open(fullUrl, '_blank');
    } finally {
      await loading.dismiss();
    }
  }
}
